import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { payHistory } from '../../../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';

function PayHistory() {

    const [paymentHistoryData, setPaymentHistoryData] = useState([]);

    const payHistoryDetail = async () => {
        try {
            const response = await axios.get(payHistory);
            setPaymentHistoryData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        payHistoryDetail()
    }, [])

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const paymentHistoryDetails = [
        {
            id: 1,
            number: '3',
            head: 'Pay Success',
            dueDate: '05/02/24',
            amount: '₹5000',
            latePayment: '₹300',
            netPayment: '₹5,300',
            paymentOn: '08/02/24',
            paymentMethod: 'UPI',
            transactionNo: 'ABCXXXX12XXX02'
        },
        {
            id: 2,
            number: '2',
            head: 'Pay Success',
            dueDate: '05/01/24',
            amount: '₹5500',
            latePayment: '₹0',
            netPayment: '₹5,500',
            paymentOn: '05/01/24',
            paymentMethod: 'UPI',
            transactionNo: 'ABCXXXX12XXX02'
        },
        {
            id: 3,
            number: '1',
            head: 'Pay Success',
            dueDate: '05/12/23',
            amount: '₹5500',
            latePayment: '₹0',
            netPayment: '₹5,500',
            paymentOn: '05/12/23',
            paymentMethod: 'UPI',
            transactionNo: 'ABCXXXX12XXX02'
        },
    ]



    return (
        <div>
            <div>
                <Row>
                    <div>
                        {paymentHistoryData.map((payHisotry) => (
                            <div className='mt-2 mb-2 p-2'>
                                <div>
                                    <Col lg={9} md={9} sm={12}>
                                        <Card>
                                            <div className='p-2'>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', color: '#ED292D' }}>
                                                        <span className='ms-1'>#</span>
                                                        <h5 className=' mt-1 ms-1'>{payHisotry?.number}</h5>
                                                    </div>
                                                    <div style={{ marginLeft: 'auto', background: '#E7FBF0', color: '#08CF65', padding: '7px', borderRadius: '7px' }}>
                                                        <h5>{payHisotry?.status}</h5>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='mt-3'>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Due Date :</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{formatDate(payHisotry?.dueDate)}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Amount:</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{payHisotry?.amount}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Late Payment:</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{payHisotry?.latePayment}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Net Payment:</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{payHisotry?.amount}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Payment On:</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{formatDate(payHisotry?.paidOn)}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Payment Method:</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{payHisotry?.payment_type}</h6></div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div><h6>Transaction No :</h6></div>
                                                        <div style={{ marginLeft: 'auto' }}><h6>{payHisotry?.bank_transaction_id}</h6></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </div>
                            </div>
                        ))}
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default PayHistory