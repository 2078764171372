import React, { useEffect, useState } from 'react'
import './AllChits.css';
import axios from 'axios';
import { chits } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { Card, Col, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';


function AllChits() {
    const [chitName, setChitName] = useState('');
    const [chitValue, setChitValue] = useState('');
    const [chitFrom, setChitFrom] = useState('');
    const [chitTo, setChitTo] = useState('');
    const [chitGroup, setChitGroup] = useState('');
    const [dueType, setDueType] = useState('');
    const [periodType, setPeriodType] = useState('');

    const {
        handleSubmit: filterFormSubmit,
        control: filterControl,
        formState: { errors: customerDetailError },
        reset,
    } = useForm({ mode: 'onChange' })

    const clearFilters = () => {
        reset();
        setChitName('');
        setChitFrom('');
        setChitTo('');
        setChitGroup('');
        setDueType('');
        setPeriodType('');
    };
    const navToHome = () => {
        navigate('/')
    }

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const chitCardData = async () => {
        try {
            const response = await axios.get(`${chits}/?search=${chitName}&from=${chitFrom}&to=${chitTo}&status=${chitGroup}&durationType=${dueType}&totalInstalment=${periodType}`);
            setData(response?.data?.result?.rows);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        chitCardData();
    }, [chitName, chitFrom, chitTo, chitGroup, dueType, periodType])

    useEffect(() => {
        chitCardData();
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            {loading ? (
                <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        size=""
                        style={{ color: '#D32728' }}
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div style={{ marginTop: '110px' }}>
                    <div><p className='solution-bredcrump pt-3' style={{ cursor: 'pointer' }}><span onClick={navToHome}>Home</span> <span style={{ color: '#D32728' }}><IoIosArrowForward /> </span><span style={{ color: 'grey' }}>All Chits</span> </p></div>
                    <Row>
                        <Col sm={12} md={3} lg={3}>
                            <div className='mt-3'>
                                <Card>
                                    <Form onSubmit={filterFormSubmit(chitCardData)}>
                                        <div>
                                            <div className='d-flex mt-4' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                                <div>  <Label>Filter</Label></div>
                                                <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                                                    <h6 style={{ color: '#ED292D' }} onClick={clearFilters}>Clear</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                                <div className='search-bar'>
                                                    <div><h6 className='filter-title mt-2'>Search</h6>
                                                    </div>
                                                    {/* <div className='search-icon' ><CiSearch size={30} /></div> */}
                                                    <div>
                                                        <Controller
                                                            name='name'
                                                            id='name'
                                                            control={filterControl}
                                                            defaultValue=''
                                                            render={({ field }) => (
                                                                <div style={{ position: 'relative', width: '100%' }}>
                                                                    <Input type='search'
                                                                        {...field} placeholder=''
                                                                        onChange={(e) => {
                                                                            field.onChange(e);
                                                                            setChitName(e.target.value);
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className='d-flex'>  <p className='mt-1 me-2'>From :</p></div>
                                                        <div className='me-2'>
                                                            <Controller
                                                                name='from'
                                                                id='from'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='tell'
                                                                            {...field} placeholder=''
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                                setChitFrom(e.target.value);
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <input type='tell' placeholder='₹' style={{ width: '70%', height: '40px' }} /> */}
                                                    </div>
                                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className='d-flex'>  <p className='mt-1 me-2'>To :</p></div>
                                                        <div className='ms-2'>
                                                            <Controller
                                                                name='to'
                                                                id='to'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='tell'
                                                                            {...field} placeholder=''
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                                setChitTo(e.target.value);
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <input type='tell' placeholder='₹' style={{ width: '70%', height: '40px' }} /> */}
                                                    </div>
                                                </div>
                                                <div className='mt-3 '>
                                                    <h6 className='filter-title mt-2'>Chit Group</h6>
                                                    <div>
                                                        <div className='filter-group'>
                                                            <div>
                                                                <Controller
                                                                    name='newChit'
                                                                    id='newChit'
                                                                    control={filterControl}
                                                                    defaultValue=''
                                                                    render={({ field }) => (
                                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                                            <div
                                                                                className='filter-group-content'
                                                                                onClick={(e) => {
                                                                                    field.onChange(e);
                                                                                    setChitGroup('upcoming');
                                                                                }}
                                                                            >
                                                                                New Chit Group
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            {/* <p className='filter-group-content pt-2'>New Chit Group</p> */}
                                                        </div>
                                                        <div className='filter-group'>
                                                            <div>
                                                                <Controller
                                                                    name='onGoing'
                                                                    id='onGoing'
                                                                    control={filterControl}
                                                                    defaultValue=''
                                                                    render={({ field }) => (
                                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                                            <div
                                                                                className='filter-group-content'
                                                                                onClick={(e) => {
                                                                                    field.onChange(e);
                                                                                    setChitGroup('ongoing');
                                                                                }}
                                                                            >
                                                                                Ongoing Chit Group
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            {/* <p className='filter-group-content pt-2'></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className='filter-title mt-2'>Due Type</h6>
                                                    <div className='d-flex'>
                                                        <div className='filter-group'>
                                                            <div>
                                                                <Controller
                                                                    name='week'
                                                                    id='week'
                                                                    control={filterControl}
                                                                    defaultValue=''
                                                                    render={({ field }) => (
                                                                        <div style={{ position: 'relative', width: '100%' }}>
                                                                            <div
                                                                                className='filter-group-content'
                                                                                onClick={(e) => {
                                                                                    field.onChange(e);
                                                                                    setDueType('week');
                                                                                }}
                                                                            >
                                                                                Weekly
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                            {/* <p className='filter-group-content pt-2'></p> */}
                                                        </div>
                                                        <div className='filter-group'>
                                                            <Controller
                                                                name='month'
                                                                id='month'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <div
                                                                            className='filter-group-content'
                                                                            onClick={(e) => {
                                                                                field.onChange(e);
                                                                                setDueType('month');
                                                                            }}
                                                                        >
                                                                            Monthly
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                            {/* <p className='filter-group-content pt-2'></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className='filter-title mt-2'>Period Type</h6>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <Controller
                                                                name='year1'
                                                                id='year1'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='checkbox'
                                                                            {...field} placeholder=''
                                                                            onClick={(e) => {
                                                                                field.onChange(e);
                                                                                setPeriodType('12');
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <Input type='checkbox' /> */}
                                                        <p className='ms-2'>12 Month</p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <Controller
                                                                name='year2'
                                                                id='year2'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='checkbox'
                                                                            {...field} placeholder=''
                                                                            onClick={(e) => {
                                                                                field.onChange(e);
                                                                                setPeriodType('26');
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <Input type='checkbox' /> */}
                                                        <p className='ms-2'>26 Month</p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <Controller
                                                                name='year3'
                                                                id='year3'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='checkbox'
                                                                            {...field} placeholder=''
                                                                            onClick={(e) => {
                                                                                field.onChange(e);
                                                                                setPeriodType('38');
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <Input type='checkbox' /> */}
                                                        <p className='ms-2'>38 Month</p>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <Controller
                                                                name='year4'
                                                                id='year4'
                                                                control={filterControl}
                                                                defaultValue=''
                                                                render={({ field }) => (
                                                                    <div style={{ position: 'relative', width: '100%' }}>
                                                                        <Input type='checkbox'
                                                                            {...field} placeholder=''
                                                                            onClick={(e) => {
                                                                                field.onChange(e);
                                                                                setPeriodType('50');
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* <Input type='checkbox' /> */}
                                                        <p className='ms-2'>50 Month</p>
                                                    </div>
                                                </div>
                                                {/* <hr />
                                                <div className='p-3'>
                                                    <button type='submit' className='chit-card-button'>Apply</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                        </Col>
                        <Col sm={12} md={9} lg={9}>
                            <div className=' mt-3'>
                                <Row>
                                    {data?.map((eachDetail, i) => (
                                        <Col sm={6} md={6} lg={6} key={i}>
                                            <Card className='mb-4' style={{ boxShadow: ' 0px 18px 22px rgba(0, 0, 0, 0.1)' }}>
                                                <div className="d-flex mt-2" style={{ color: '#ED292D', padding: '15px' }}>
                                                    <div className='d-flex'>
                                                        <img src={eachDetail?.img_Url} style={{ height: '50px', width: '50px', objectFit: 'cover', borderRadius: '100%' }} />
                                                        <h5 className='trending-card-head ms-2 mt-1'>{eachDetail?.chitName.length > 25 ? `${eachDetail?.chitName.slice(0, 25)}` : eachDetail?.chitName}</h5>
                                                    </div>
                                                    <div style={{ marginLeft: 'auto', textAlign: 'end' }}>
                                                        <h6 className='trending-card-left'>Chit Id</h6>
                                                        <h6 className='trending-card-left'>{eachDetail?.chit_id.length > 10 ? `${eachDetail?.chit_id.slice(0, 10)}` : eachDetail?.chit_id}</h6>
                                                    </div>

                                                </div>
                                                {/* <h6 className='trending-card-left'>{eachDetail?.description}</h6> */}

                                                <hr style={{ marginTop: '0px' }} />
                                                <div className='d-flex p-3'>
                                                    <div style={{ textAlign: 'start' }}>
                                                        <h5 className='trending-card-left-head'>Chit Value:</h5>
                                                        <h6 className='trending-card-left'>Available Ticket</h6>
                                                        <h6 className='trending-card-left'>Duration</h6>
                                                        <h6 className='trending-card-left'>Installment Amount</h6>
                                                        <h6 className='trending-card-left'>Duration Type</h6>
                                                        <h6 className='trending-card-left'>Chit Type</h6>
                                                    </div>
                                                    <div style={{ textAlign: 'end', marginLeft: 'auto' }}>
                                                        <h5 className='trending-card-rate '>{eachDetail?.oneInstalmentTotalAmount?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 0,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h5>
                                                        <h6 className='trending-card-right'>{eachDetail?.membersCount}</h6>
                                                        <h6 className='trending-card-right'>{eachDetail?.totalInstalment} {eachDetail?.durationType}</h6>
                                                        <h6 className='trending-card-right'>{eachDetail?.oneInstalment?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 0,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h6>
                                                        <h6 className='trending-card-right'>{eachDetail?.durationType}</h6>
                                                        <h6 className='trending-card-right'>{eachDetail?.status}</h6>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='p-3'>
                                                    <button type='submit' className='chit-card-button' onClick={() => navigate(`/chitView/${eachDetail?._id}`)}>View Plan</button>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

export default AllChits