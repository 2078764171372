import { useEffect } from 'react';
import './App.css';
import ChitApp from './containers/chitApp/ChitApp';
import ChitCycle from './containers/chitCycle/ChitCycle';
import Herosection from './containers/herosection/Herosection';
import OurSolution from './containers/ourSolution/OurSolution';
import Testimonials from './containers/testimonial/Testimonials';
import TrendingChit from './containers/trendingChit/TrendingChit';
import WhyChooseUs from './containers/whyChoosUs/WhyChooseUs';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

      <div className="App">
        <Herosection />
        <OurSolution />
        <TrendingChit />
        <WhyChooseUs />
        <ChitCycle />
        <ChitApp />
        <Testimonials />
      </div>

    </>

  );
}

export default App;
