export const baseUrl = 'https://devapi.chitbid.com/';


const otpSend = `${baseUrl}otp/send`;
const otpVerify = `${baseUrl}otp/verify`;
const chits = `${baseUrl}auth/chits`;
const goals = `${baseUrl}auth/goalType`;
const joinChit = `${baseUrl}joinchit`;
const userDetail = `${baseUrl}userDetails`;
const myChits = `${baseUrl}myChitsDetails`;
const installment = `${baseUrl}instalment`;
const payHistory = `${baseUrl}paymentHistory`;
const auctionWinner = `${baseUrl}auctionWinner`;
const joinChitAmount = `${baseUrl}auth/joinChitAmount`;




export {
    otpSend,
    otpVerify,
    chits,
    goals,
    joinChit,
    userDetail,
    myChits,
    installment,
    payHistory,
    auctionWinner,
    joinChitAmount,
}


