import React, { useState } from 'react';
import { XCircle } from 'react-feather-icon';
import { Controller, useForm } from 'react-hook-form';
import { FaPhone } from 'react-icons/fa6';
import { Form, FormFeedback, Input, Label, Modal, ModalBody } from 'reactstrap';
import { otpSend } from '../../ApiConfigs/ApiConfigs';
import axios from 'axios';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import fblogo from '../../assets/images/logo/logos_facebook.png';
import maillogo from '../../assets/images/logo/skill-icons_gmail-light.png';
import OtpSend from './OtpSend';
import './MobileOtp.css';

function MobileOtp({ isOpen, toggle }) {
    const logInMobileSchema = yup.object().shape({
        mobile: yup.string().required('Please Fill Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
    });

    const {
        handleSubmit: mobileFormSubmit,
        control: mobileControl,
        formState: { errors: mobileErrors },
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(logInMobileSchema) })

    const [otpModal, setOtpModal] = useState(false);


    const toggleOtpModal = () => {
        setOtpModal(!otpModal);
    }

    const handleModalClose = () => {
        reset({ mobile: '' })
        toggle();
    }
    const handleMobileLogIn = async (data) => {

        try {
            const response = await axios.post(otpSend, data);
            toast.success(response?.data?.msg);
            localStorage.setItem('SignInMobile', data.mobile);
            toggle();
            toggleOtpModal();
            reset();
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={() => {
                toggle()
                reset({ mobile: '' })
            }}>

                <ModalBody>
                    <Form onSubmit={mobileFormSubmit(handleMobileLogIn)}>
                        <div style={{ padding: "0% 3%" }}>
                            <div className='d-flex'>
                                <p className='auth-modal'> Login</p>
                                <div className='auth-modal-close' onClick={handleModalClose} > <XCircle size={28} /></div>
                            </div>
                            <div>
                                <Label className='auth-modal-phonenumber'>Phone Number</Label>
                                <div className='d-flex'>
                                    <span className='number-icon ' style={{ height: '46px' }}><FaPhone /> </span>
                                    <Controller
                                        name='mobile'
                                        id='mobile'
                                        control={mobileControl}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <Input type='tel' style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderColor: '#484848', padding: '10px' }}
                                                    {...field} invalid={!!mobileErrors.mobile} placeholder='Enter Your 10 - Digit Mobile Number' />
                                                {mobileErrors.mobile && <FormFeedback>{mobileErrors.mobile.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                                <button type='submit' className='auth-modal-submitbn'>Continue</button>
                                <div className='d-flex mt-3'>
                                    <hr className='auth-modal-line' />
                                    <span className='auth-modal-or'>or</span>
                                    <hr className='auth-modal-line' />
                                </div>
                                <div className='auth-facebook'>
                                    <img src={fblogo} height={35} />
                                    <span className='fb-content'>Continue With Facebook</span>
                                </div>
                                <div className='auth-facebook mt-3 mb-3'>
                                    <img src={maillogo} height={35} />
                                    <span className='fb-content'>Continue With EMAIL</span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            <OtpSend isOpen={otpModal} toggleOtpModal={toggleOtpModal} />

        </div>
    )
}

export default MobileOtp