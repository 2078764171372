import React from 'react'
import './ChitApp.css';
import { Col, Row } from 'reactstrap'
import screen from '../../assets/images/application/Group 1410110184.png';
import sky from '../../assets/images/application/Group 1410110186.png';
import mobi from '../../assets/images/application/Group 1410110187.png';
import app from '../../assets/images/application/Group 1410110188.png';
import { IoLogoApple } from "react-icons/io";
import { FaGooglePlay } from "react-icons/fa";



function ChitApp() {
    return (
        <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', background: '#FEF3F2' }}>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <h2 className='chit-app-header'>How Does This App Work ?</h2>
                    <div className='our-solution-line' ></div>
                    <div className='d-flex chit-app-leftone'>
                        <div className='chit-app-system'>
                            <img src={screen} height={100} />
                        </div>
                        <div>
                            <h5 className='chit-app-secheade'>Make a Profile</h5>
                            <p className='chit-app-secdesc'>Unlock exclusive deals and discounts with our mobile app, your ultimate money-saving companion</p>
                        </div>
                    </div>
                    <div className='chit-app-line'></div>
                    <div className='d-flex chit-app-lefttwo'>
                        <div className='chit-app-system'>
                            <img src={sky} height={100} />
                        </div>
                        <div>
                            <h5 className='chit-app-secheade'>Free Download</h5>
                            <p className='chit-app-secdesc'>Unlock exclusive deals and discounts with our mobile app, your ultimate money-saving companion</p>
                        </div>
                    </div>
                    <div className='chit-app-line'></div>
                    <div className='d-flex chit-app-leftthree'>
                        <div className='chit-app-system'>
                            <img src={mobi} height={100} />
                        </div>
                        <div>
                            <h5 className='chit-app-secheade'>Safe & Secure</h5>
                            <p className='chit-app-secdesc'>Unlock exclusive deals and discounts with our mobile app, your ultimate money-saving companion</p>
                        </div>
                    </div>
                    <div className='d-flex' style={{ justifyContent: 'center', marginBottom: '50px' }}>
                        <div className='me-5' style={{ justifyContent: 'center', display: 'flex' }}>
                            <button type='button' className='chit-app-storesbt'>
                                <div className='d-flex'>
                                    <div>
                                        <IoLogoApple className='mt-2' size={35} />
                                    </div>
                                    <div className='text-start ms-4 me-3'>
                                        <p className=''> Available On</p>
                                        <p className='chit-app-store'>APPLE STORE</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <button type='button' className='chit-app-storesbt'>
                                <div className='d-flex'>
                                    <div>
                                        <FaGooglePlay className='mt-2' size={35} />
                                    </div>
                                    <div className='text-start ms-4 me-3'>
                                        <p className=''> Available On</p>
                                        <p className='chit-app-store'>GOOGLE PLAY</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>

                </Col>
                <Col lg={6} md={6} sm={12}>
                    <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginTop: '90px' }}>
                        <img src={app} size={300} style={{ height: '100%' }} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ChitApp                                  