import React, { useEffect, useState } from 'react'
import './SingleChit.css';
import { Card, Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import design from '../../assets/images/solutions/Frame 1597881730 (1).png';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { chits, joinChit, joinChitAmount } from '../../ApiConfigs/ApiConfigs';
import { CiLocationOn } from 'react-icons/ci';
import pdflogo from '../../assets/images/pdf/Capa_1.png';
import pptlogo from '../../assets/images/pdf/image 1244.png';
import wordlogo from '../../assets/images/pdf/Word (1).png';
import CustomerDetails from '../customerDetails/CustomerDetails';
import MobileOtp from '../../components/navbar/MobileOtp';
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { IoIosArrowForward } from "react-icons/io";
import { IoArrowForwardSharp } from "react-icons/io5";


function SingleChit() {

    const [chitData, setChitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showMobileOTPModal, setShowMobileOTPModal] = useState(false);


    const { id } = useParams();
    const chitCardData = async () => {
        try {
            const response = await axios.get(`${chits}/${id}`);
            setChitData(response?.data?.result);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };


    const [initialAmount, setInitialAmount] = useState('');

    const chitInitialAmount = async () => {
        try {
            const response = await axios.get(`${joinChitAmount}?chit_id=${id}`);
            setInitialAmount(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }



    useEffect(() => {
        chitCardData();
        chitInitialAmount();
        window.scrollTo(0, 0);
    }, [id]);



    const [paymentData, setPaymentData] = useState([])
    const navigate = useNavigate();
    const handlePayButtonClick = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setShowMobileOTPModal(true);
                return;
            }


            const response = await axios.post(joinChit, { chit: id });
            const paymentLink = response?.data?.result?.paymentLink;
            if (paymentLink) {
                window.location.href = paymentLink;
                setPaymentData(response?.data?.result);
            } else {
                toast.error(response?.data?.msg);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };


    const memberListCount = chitData?.chits?.membersList?.length;

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const navToHome = () => {
        navigate('/')
    }

    const navToGoalbased = () => {
        if (chitData?.goalType?.type === 'personal') {
            navigate('/personalDetails');
        } else {
            navigate('/solutions');
        }
    }



    return (
        <div>
            {loading ? (
                <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        size=""
                        style={{ color: '#D32728' }}
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <>
                    <div style={{ marginTop: '100px', paddingLeft: '8%', paddingRight: '8%' }}>
                        <Row className='solution-row'>
                            <Col lg={8} md={8} sm={12}>
                                <div>
                                    <div className='single-chit-top'>
                                        <div className='single-chit-top-bck'>
                                            <div>
                                                <img src={design} style={{ height: '300px' }} />
                                            </div>
                                            <div className='single-chit-top-contents'>
                                                <>
                                                    <h4>{chitData?.goalType?.title}</h4>
                                                    <div className='solution-line'></div>
                                                    {chitData?.goalType?.points.map((point) => (
                                                        <div key={point} className='mt-3 d-flex'>
                                                            <VscDebugBreakpointLog className='mt-1' /> <p>{point?.title}</p>
                                                        </div>
                                                    ))}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='growthinvest-top-left-bck'>
                                    <div >
                                        <img src={chitData?.goalType?.image} className='growthinvest-top-left-img' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                        <div><p className='solution-bredcrump mt-2' style={{ cursor: 'pointer' }}>
                            <span onClick={navToHome}>Home</span>
                            <span style={{ color: '#D32728' }}><IoIosArrowForward /> </span>
                            <span onClick={navToGoalbased}>{chitData?.goalType?.type} Solutions</span>
                            <span style={{ color: '#D32728' }}><IoIosArrowForward /> </span>
                            <span style={{ color: 'grey' }}>{chitData?.goalType?.title}</span>
                        </p></div>

                        <div className='mt-4 mb-5'>
                            <Row>
                                <Col lg={8} md={8} sm={8}>
                                    <Card style={{ boxShadow: ' 0px 18px 22px rgba(0, 0, 0, 0.1)' }}>
                                        <div >
                                            <div>
                                                <div className='d-flex' style={{ paddingLeft: '10px', paddingRight: '10px', borderBottom: '1px solid #D2D2D2' }}>
                                                    <div className='d-flex'>
                                                        <img src={chitData?.chits?.img_Url} style={{ height: '50px', width: '50px', marginTop: '15px', objectFit: 'cover', borderRadius: '100%' }} />
                                                        <h5 className='single-chit-name ms-3'>{chitData?.chits?.chitName}</h5>
                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <h6 className='text-center mt-2'>Chit Id</h6>
                                                        <p className='single-chit-id'>{chitData?.chits?.chit_id}</p>
                                                    </div>
                                                </div>
                                                {/* <hr /> */}
                                                <div className='mt-4' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                                    <Row>
                                                        <Col lg={3} md={3} sm={3}>
                                                            <div>
                                                                <h5 className='single-chit-head'>Chit Value</h5>
                                                                <h5 className='single-chit-amnt'>{chitData?.chits?.oneInstalmentTotalAmount?.toLocaleString('en-IN', {
                                                                    maximumFractionDigits: 0,
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                })}</h5>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={3} sm={3}>
                                                            <div >
                                                                <h6 className='single-chit-head'>Member's</h6>
                                                                <h6 className='single-chit-member'>{memberListCount}/<span className='single-chit-body'>{chitData?.chits?.membersCount}</span></h6>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={3} sm={3}>
                                                            <div>
                                                                <h5 className='single-chit-head'>Due Amount</h5>
                                                                <h5 className='single-chit-body'>{chitData?.chits?.oneInstalment?.toLocaleString('en-IN', {
                                                                    maximumFractionDigits: 0,
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                })}</h5>
                                                            </div>
                                                        </Col>

                                                        <Col lg={3} md={3} sm={3}>
                                                            <div>
                                                                <h5 className='single-chit-head'>Due Type</h5>
                                                                <h5 className='single-chit-body'>{chitData?.chits?.durationType}</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-5'>
                                                        <Row>
                                                            <Col lg={3} md={3} sm={3}>
                                                                <div>
                                                                    <h5 className='single-chit-head'>Installment</h5>
                                                                    <h5 className='single-chit-body'>{chitData?.chits?.totalInstalment} {chitData?.chits?.durationType}</h5>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={3} sm={3}>
                                                                <div>
                                                                    <h5 className='single-chit-head'>Chit Type</h5>
                                                                    <h5 className='single-chit-body'>{chitData?.chits?.chitType}</h5>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={3} sm={3}>
                                                                <div>
                                                                    <h5 className='single-chit-head'>Chit Start Date</h5>
                                                                    <h5 className='single-chit-body'>{formatDate(chitData?.chits?.startDate)}</h5>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={3} sm={3}>
                                                                <div>
                                                                    <h5 className='single-chit-head'>Least Percentage</h5>
                                                                    <h5 className='single-chit-body'>{chitData?.chits?.leastAuctionPercentage}%</h5>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div style={{ display: 'flex', paddingLeft: '10px', paddingRight: '10px', marginBottom: '10px' }}>
                                                    <div className='d-flex alignItems-center'>
                                                        <h5 className='single-chit-head me-2'>Initial Pay Amount - </h5>
                                                        <h5 className='single-chit-head ms-1'> {initialAmount?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 0,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h5>
                                                    </div>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <button type='button' className='single-chit-button' onClick={handlePayButtonClick} >Pay & Join Chit <IoArrowForwardSharp /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={4} md={4} sm={4}>
                                    <Card className='mb-2' style={{ boxShadow: ' 0px 18px 22px rgba(0, 0, 0, 0.1)' }}>
                                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                            <h5 className='single-chit-address mt-3'>Contact Branch</h5>
                                            <Row>
                                                <Col lg={6} md={6} sm={6}>
                                                    <div className='d-flex'>
                                                        <p className='single-chit-location mt-1'>Name</p>
                                                        <div className='mt-1' style={{ marginLeft: 'auto' }}>:</div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6}>
                                                    <div className='mt-1'>
                                                        <p className='single-chit-mrname'>{chitData?.chits?.chitManager?.name}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6}>
                                                    <div className='d-flex'>
                                                        <p className='single-chit-location'>Mobile</p>
                                                        <div style={{ marginLeft: 'auto' }}>:</div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6}>
                                                    <div>
                                                        <p className='single-chit-mrname'>+{chitData?.chits?.chitManager?.mobile}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6} md={6} sm={6}>
                                                    <div className='d-flex' >
                                                        <CiLocationOn style={{ height: '30px', width: '30px' }} />
                                                        <h5 className='single-chit-location mt-2'>Branch Location</h5>
                                                        <div className='mt-2' style={{ marginLeft: 'auto' }}>:</div>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={6} sm={6}>
                                                    <h5 className='single-chit-location mt-2'>{chitData?.chits?.location} </h5>
                                                </Col>
                                            </Row>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '8px' }}>
                                                <button type='button' className='single-chit-button mt-4' >Contact Near Branch</button>
                                            </div>

                                        </div>
                                    </Card>
                                    <Card className='mt-2 p-2' style={{ boxShadow: ' 0px 18px 22px rgba(0, 0, 0, 0.1)' }}>
                                        <Row>
                                            <div className='document-head mb-1'>Document</div>
                                            <Col lg={4} md={4} sm={4}>
                                                <div style={{ cursor: 'pointer' }} onClick={() => window.open(chitData?.chits?.document1)} >
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img src={wordlogo} style={{ height: '40px', width: '40px' }} />
                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p>PSO</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <div style={{ cursor: 'pointer' }} onClick={() => window.open(chitData?.chits?.document2)}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img src={pptlogo} style={{ height: '40px', width: '40px' }} />
                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p>FDR</p>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <div style={{ cursor: 'pointer' }} onClick={() => window.open(chitData?.chits?.document3)}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <img src={pdflogo} style={{ height: '40px', width: '40px' }} />
                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        <p>Agreement</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div>
                        </div>
                    </div>

                </>
            )
            }
            <MobileOtp isOpen={showMobileOTPModal} toggle={() => setShowMobileOTPModal(!showMobileOTPModal)} />
        </div >
    )
}

export default SingleChit