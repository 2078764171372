import React, { useEffect, useState } from 'react'
import './PersonalDetails.css';
import { Card, Col, Row, Spinner } from 'reactstrap';
import design from '../../assets/images/solutions/Frame 1597881730 (1).png';
import family from '../../assets/images/solutions/indian-asian-family-sitting-white-background-senior-young-couple-with-kids-wearing-white-top-blue-jeans-s (2).png';
import { VscDebugBreakpointLog } from "react-icons/vsc";
import axios from 'axios';
import { goals } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";


function PersonalDetails() {

    const [personalData, setPersonalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const personalisedData = async () => {
        try {
            const response = await axios.get(`${goals}?type=personal`);
            setPersonalData(response?.data?.result);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        personalisedData();
        window.scrollTo(0, 0);
    }, []);

    const navToHome = () => {
        navigate('/')
    }

    return (
        <div>
            <div>
                {loading ? (
                    <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            size=""
                            style={{ color: '#D32728' }}
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <div style={{ marginTop: '100px', paddingLeft: '8%', paddingRight: '8%' }}>
                        <Row className='solution-row'>
                            <Col lg={8} md={8} sm={12}>
                                <div className='solution-must mb-5'>
                                    <div className='solution-top-one'>
                                        <div className='solution-top '>
                                            <div className='solution-design'>
                                                <img src={design} />
                                            </div>
                                            <div className='solution-contents' >
                                                <h4 className='solution-head-content'>Personalised Solution</h4>
                                                <div className='solution-line'></div>
                                                <p className='solution-head-desc'>Based on your goals and savings capacity, we match you with the most suitable chit group. Whether you're looking for a high-return investment or steady savings, we have options for everyone.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='solution-left-clr ml-auto'>
                                    <div data-aos="zoom-in" >
                                        <img src={family} height={160} className='mt-5' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <div><p className='solution-bredcrump pt-3' style={{ cursor: 'pointer' }}><span onClick={navToHome}>Home</span> <span style={{ color: '#D32728' }}><IoIosArrowForward /> </span><span style={{ color: 'grey' }}>Personalised Solutions</span> </p></div>
                            <div>
                                <div className='mb-5'>
                                    <h2 className='solutions-heading'>Personal Solutions</h2>
                                    <div className='solution-head-line' style={{ margin: '0 auto' }} ></div>
                                    <p className='solution-desc mt-3'><span className='solution-desc-dark'>CHITBID</span> chits is specifically designed to enable you to save and meet your borrowing needs,all at once</p>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Row>
                                        {personalData?.rows?.map((details, i) => (
                                            <Col lg={6} md={6} sm={12}>
                                                <Card className='mb-4'>
                                                    <div>
                                                        <Row>
                                                            <Col lg={5} md={5} sm={5}>
                                                                <img src={details.image} style={{ width: '100%', height: '100%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} />
                                                            </Col>
                                                            <Col lg={7} md={7} sm={7}>
                                                                <div className='mt-2'>
                                                                    <h2>{details.title}</h2>
                                                                    <p>{details.description}</p>
                                                                    {details.details.map((detail, index) => (
                                                                        <p key={index}>
                                                                            <span style={{ color: '#ED292D' }}><VscDebugBreakpointLog /></span>{detail.title}
                                                                        </p>
                                                                    ))}
                                                                </div>
                                                                <div className='pe-3'>
                                                                    <button type='submit' className='chit-card-button' onClick={() => navigate(`/growthInvestments/${details?._id}`)}>View More</button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PersonalDetails