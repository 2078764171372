import React from 'react'
import './PageNotFound.css';
import notfound from '../../assets/images/404/404 error.png';

function PageNotFound() {
    return (
        <div className='error-page' style={{ marginTop: '80px' }}>
            <img src={notfound} />
        </div>
    )
}

export default PageNotFound