import React, { useEffect, useState } from 'react';
import './NavbarTop.css';
import brand from '../../assets/images/logo/CHit Logo.png';
import MobileOtp from './MobileOtp';
import { FaUserCircle } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { AiOutlineLogout } from "react-icons/ai";
import { useAuth } from '../../AuthContext';



function NavbarTop() {
    const [token, setToken] = useState();
    const [modal, setModal] = useState(false);
    const [isLogedin, setIsLogedin] = useState(false)
    const location = useLocation();
    const { isLoggedIn, logout } = useAuth();

    const toggle = () => {
        setModal(!modal)
    }

    const mobile = localStorage.getItem('mobile')
    const [logoutValue, setLogoutValue] = useState(false)

    const logoutFn = () => {
        setLogoutValue(true)
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('mobile');
        setIsLogedin(false)
        logout()
    }


    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLogedin(token !== null);
    }, []);


    return (
        <div>
            <div>
                {location.pathname !== '/customer-details' && (
                    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top shadow" style={{ paddingLeft: '7%', paddingRight: '7%' }}>
                        <div className="container-fluid ">
                            <div style={{ padding: '3px' }}>
                                <Link className="navbar-brand" to={'/'}>
                                    <img src={brand} className='chit-logo' />
                                    <b className='ms-2' style={{ color: '#ED292D' }}>CHITBID</b>
                                </Link>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: 'space-between' }}>
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                                    <li className="nav-item">
                                        <Link className=" topbar-list nav-link" aria-current="page" to={'/'}><b className='topbar-menus'>Home</b></Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="topbar-list nav-link" to={'/allchits'} href="#" ><b className='topbar-menus'>Chit's</b></Link>
                                    </li>
                                    <li className="nav-item">
                                        {/* <Link className="topbar-list nav-link" to={'/solutions'} href="#" ><b className='topbar-menus'>Solution's</b></Link> */}
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret>
                                                <b className=' topbar-menus'>Solution's</b>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <Link to={'/solutions'} className='nav-top-drop'>Goal Based Solutions</Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to={'/personalDetails'} className='nav-top-drop'>Personal Solutions</Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                    <li className="nav-item">
                                        <a className="topbar-list nav-link" href="#"><b className='topbar-menus'>About</b></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="topbar-list nav-link" href="#"><b className='topbar-menus'>Certification</b></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="topbar-list nav-link" href="#"><b className='topbar-menus'>Contact Us</b></a>
                                    </li>
                                </ul>
                                {isLoggedIn ? (
                                    <>
                                        <UncontrolledDropdown style={{ marginTop: '5px', textDecoration: 'none', listStyle: 'none' }} tag="li" className="dropdown-user nav-item">
                                            <DropdownToggle
                                                href="/"
                                                tag="a"
                                                className="nav-link dropdown-user-link"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <div className="d-flex" style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                                                    <FaUserCircle size={25} />
                                                    <h5 className='nav-top-header-head mt-2 ms-1' style={{ justifyContent: 'center', alignItems: 'center', }}>{mobile && mobile}</h5>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu end>
                                                <>
                                                    <Link className='user-dropdown' to={'/myChit'} >
                                                        <DropdownItem tag='a'> My Chit</DropdownItem>
                                                    </Link>
                                                    <hr className='m-0 p-0' />
                                                    <Link className='user-dropdown'>
                                                        <DropdownItem tag='a'> My Plan</DropdownItem>
                                                    </Link>
                                                    <hr className='m-0 p-0' />
                                                    <Link className='user-dropdown'>
                                                        <DropdownItem tag='a'> My Profile</DropdownItem>
                                                    </Link>
                                                    <hr className='m-0 p-0' />
                                                    <Link to='/' className='user-dropdown'>
                                                        <DropdownItem tag='a' className="text-danger" onClick={logoutFn}><AiOutlineLogout /> Log Out</DropdownItem>
                                                    </Link>
                                                </>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                    </>
                                ) : (
                                    <button className="topbar-submit" type="submit" onClick={() => setModal(true)}>Get Start</button>
                                )}

                            </div>

                        </div>
                    </nav >
                )}
            </div>

            <MobileOtp isOpen={modal} toggle={toggle} />
        </div >
    );
}

export default NavbarTop;
