import React, { useState } from 'react'
import './Footer.css';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';
import logo from '../../assets/images/logo/CHit Logo.png';
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useAuth } from '../../AuthContext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import OtpSend from '../navbar/OtpSend';
import axios from 'axios';
import { otpSend } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


function Footer() {
    const logInMobileSchema = yup.object().shape({
        mobile: yup.string().required('Please Fill Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
    });

    const {
        handleSubmit: mobileFormSubmit,
        control: mobileControl,
        formState: { errors: mobileErrors },
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(logInMobileSchema) })

    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
    const location = useLocation();

    const { isLoggedIn, logout } = useAuth();

    const handleMobileLogIn = async (data) => {

        try {
            const response = await axios.post(otpSend, data);
            toast.success(response?.data?.msg);
            localStorage.setItem('SignInMobile', data.mobile);
            setIsOtpModalOpen(true);
            reset()
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    return (
        <div className='footer'>
            <div>
                {location.pathname !== '/customer-details' && (
                    <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                        <div className='pt-5 pb-5'>
                            <Row>
                                <Col lg={3} md={3} sm={12}>
                                    <div className='footer-first-3'>
                                        <img src={logo} className='footer-logo' />
                                        <h6 className='footer-logo-name'>CHITBIT</h6>
                                        <p className='footer-desc'>we are a part of the finanacial conglomerate chitbit pappachan group(CHITBIT),remain inspired and guided by the trust story of our group-Chitbit,build over decades</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={12}>
                                    <div className='footer-second-3'>
                                        <h5 className='footer-lef-name'>Chits</h5>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc'>The chit process - how it workings</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>security norms of chits</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>benefits of chits</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>need base solution</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>Profile based solution</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>Eligibliti criteria</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow1'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc1'>profile based solution</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={12}>
                                    <div className='footer-third-3'>
                                        <h5 className='footer-lef-name'>Other Links</h5>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc'>Branches</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>branch location</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>pay online</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>sitemap</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>privacy policy</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>Contact us</p>
                                        </div>
                                        <div className='d-flex'>
                                            <span className='footer-lef-arrow2'> <MdKeyboardArrowRight size={25} /></span>
                                            <p className='footer-lef-desc2'>FAQs</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={12}>
                                    <div style={{ width: '100%' }}>
                                        <h6 className='footer-right-name'>JOIN WITH US</h6>
                                        {isLoggedIn ? null : (
                                            <div className='footer-input mt-5'>
                                                <Form onSubmit={mobileFormSubmit(handleMobileLogIn)}>
                                                    <div>
                                                        <Controller
                                                            name='mobile'
                                                            id='mobile'
                                                            control={mobileControl}
                                                            defaultValue=''
                                                            render={({ field }) => (
                                                                <div className='why-choose-number'>
                                                                    <Input type='tell' style={{ border: 'none', padding: '15px', background: '#FEF3F2', width: '100%' }}
                                                                        {...field} invalid={!!mobileErrors.mobile} placeholder='Enter Your 10 - Digit Mobile Number' />
                                                                    {mobileErrors.mobile && <FormFeedback>{mobileErrors.mobile.message}</FormFeedback>}
                                                                </div>
                                                            )}
                                                        />
                                                        <button className="footer-submit" type="submit">Submit</button>
                                                    </div>
                                                </Form>
                                            </div>
                                        )}

                                        {/* {isLoggedIn ? null : (
                                            <div className='mt-5' style={{ background: 'white', padding: '7px', borderRadius: '4px', display: 'flex', width: '80%' }}>
                                                <Form onSubmit={mobileFormSubmit(handleMobileLogIn)}>
                                                    <div className='d-flex'>
                                                        <Controller
                                                            name='mobile'
                                                            id='mobile'
                                                            control={mobileControl}
                                                            defaultValue=''
                                                            render={({ field }) => (
                                                                <div className='footer-input-width'>
                                                                    <Input type='tell' style={{ border: 'none', padding: '10px 2px', background: '#FEF3F2', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', }}
                                                                        {...field} invalid={!!mobileErrors.mobile} placeholder='Enter Your 10 - Digit Mobile Number' />
                                                                    {mobileErrors.mobile && <FormFeedback>{mobileErrors.mobile.message}</FormFeedback>}
                                                                </div>
                                                            )}
                                                        />
                                                        <button className="footer-submit" type="submit">Submit</button>
                                                    </div>
                                                </Form>
                                            </div>
                                        )} */}
                                        <div className='d-flex' style={{ cursor: 'pointer' }}>
                                            <span className='footer-mail'> <IoMdMail size={25} /></span>
                                            <p className='footer-mail-name ms-3'>chitbid@gmail.com</p>
                                        </div>
                                        <div className='d-flex' style={{ cursor: 'pointer' }}>
                                            <span className='footer-mail1'> <FaPhoneAlt size={25} /></span>
                                            <p className='footer-mail-name1 ms-3'>+91 6383985216</p>
                                        </div>
                                        <div className='d-flex' style={{ cursor: 'pointer' }}>
                                            <span className='footer-mail1'> <FaMapMarkerAlt size={25} /></span>
                                            <p className='footer-mail-name1 ms-3'>BTM Layout,,Bengaluru </p>
                                        </div>
                                        <div className='d-flex' style={{ cursor: 'pointer' }}>
                                            <span className='footer-mail'>
                                                <FaInstagram size={25} />
                                                <FaFacebook className='ms-2' size={25} />
                                                <FaLinkedin className='ms-2' size={25} />
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </div>

            <OtpSend isOpen={isOtpModalOpen} toggleOtpModal={() => setIsOtpModalOpen(!isOtpModalOpen)} />

        </div>
    )
}

export default Footer