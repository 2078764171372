import React from 'react'
import './Testimonials.css';
import Carousel from 'react-multi-carousel';
import { FaQuoteLeft } from "react-icons/fa";
import image from '../../assets/images/testimonials/WhatsApp Image 2024-03-02 at 4.16.59 PM.jpeg';
import image1 from '../../assets/images/testimonials/WhatsApp Image 2024-03-02 at 4.31.57 PM.jpeg';
import image2 from '../../assets/images/testimonials/WhatsApp Image 2024-03-02 at 4.23.35 PM.jpeg';
import image3 from '../../assets/images/testimonials/WhatsApp Image 2024-03-02 at 4.05.04 PM.jpeg';


function Testimonials() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 767, min: 320 },
            items: 1,
            slidesToSlide: 1
        }
    };
    const testimonidetails = [
        {
            id: 1,
            name: "Karthick",
            descripton: ' I stumbled upon Chit while searching for a platform to streamline my freelance business. I was pleasantly surprised by the user-friendly interface and the range of features offered. Chit has not only simplified my invoicing   freelance business. I was pleasantly surprised by',
            img: image
        },
        {
            id: 2,
            name: "Arun Karthi",
            descripton: ' I stumbled upon Chit while searching for a platform to streamline my freelance business. I was pleasantly surprised by the user-friendly interface and the range of features offered. Chit has not only simplified my invoicing   freelance business. I was pleasantly surprised by',
            img: image1
        },
        {
            id: 1,
            name: "Logeshwaran",
            descripton: ' I stumbled upon Chit while searching for a platform to streamline my freelance business. I was pleasantly surprised by the user-friendly interface and the range of features offered. Chit has not only simplified my invoicing   freelance business. I was pleasantly surprised by',
            img: image2
        },
        {
            id: 2,
            name: "Palanikumar",
            descripton: ' I stumbled upon Chit while searching for a platform to streamline my freelance business. I was pleasantly surprised by the user-friendly interface and the range of features offered. Chit has not only simplified my invoicing   freelance business. I was pleasantly surprised by',
            img: image3
        },
    ]


    return (
        <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            <h2 className='testimoni-head mt-5'>What Clients Say About Us</h2>
            <div className='chit-head-line' style={{ margin: '0 auto' }}></div>
            <p className='testimoni-desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula malesuada velit, sed consequat metus.</p>

            <div className="parent mb-5">
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    partialVisible={true}
                    arrows={false}
                    dotListClass="custom-dot-list-style"
                >
                    {testimonidetails.map(({ name, descripton, img }, index) => {
                        return (
                            <div className="slider2" key={index}>
                                <div className='testi'>
                                    <div className='testminial-card'>
                                        <div className='testmonial-quot'><FaQuoteLeft size={45} style={{ padding: '10px' }} /></div>
                                        <div className='teatimonial-slide' >
                                            <h5 className="testimonial-name mt-5">{name}</h5>
                                            <div className='d-flex' style={{ position: 'relative' }}>
                                                <p className="testimonial-says mb-2">{descripton}</p>
                                                <img src={img} className='testi-image' />
                                            </div>
                                            <div className='testi-line'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default Testimonials