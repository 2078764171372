import React, { useEffect, useState } from 'react'
import './Herosection.css';
import bannerhero from '../../assets/images/banner/portfolio.png';
import logo1 from '../../assets/images/banner/Group 1410110140.png';
import logo2 from '../../assets/images/banner/Group 1410110174.png';
import logo3 from '../../assets/images/banner/Group 1410110175.png';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import { otpSend } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import OtpSend from '../../components/navbar/OtpSend';
import Aos from 'aos';
import CountUp from "react-countup";


function Herosection() {

    const logInMobileSchema = yup.object().shape({
        mobile: yup.string().required('Please Fill Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
    });

    const {
        handleSubmit: mobileFormSubmit,
        control: mobileControl,
        formState: { errors: mobileErrors },
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(logInMobileSchema) })

    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false); // State to control the visibility of the OTP modal

    const { isLoggedIn, logout } = useAuth();

    const handleMobileLogIn = async (data) => {

        try {
            const response = await axios.post(otpSend, data);
            toast.success(response?.data?.msg);
            localStorage.setItem('SignInMobile', data.mobile);
            setIsOtpModalOpen(true); // Open the OTP modal after submitting the mobile number form
            reset()
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    useEffect(() => {
        Aos.init({ duration: 2000 })

    }, [])

    return (
        <div className='herosection' style={{ background: '#FEF3F2', height: '100%', marginTop: '70px' }}>
            <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <Row>
                    <Col lg={6} md={6} sm={12} style={{ paddingTop: '140px' }} className='hero-media'>
                        <h1 className='banner-content'>Welcome To <spin style={{ color: '#ED292D' }}>Join Chit</spin></h1>
                        <h1 className='banner-content'>Your Trustable</h1>
                        <h1 className='banner-content'>Platform</h1>
                        <h6 className='banner-para fw-bold pt-2'>Join our 100 % digital life get in  to touch in <br /> save your money </h6>

                        {isLoggedIn ? null : (
                            <div className='mt-5' style={{ background: 'white', padding: '7px', borderRadius: '4px', display: 'flex', width: 'max-content' }}>
                                <Form onSubmit={mobileFormSubmit(handleMobileLogIn)}>
                                    <div className='d-flex'>
                                        <Controller
                                            name='mobile'
                                            id='mobile'
                                            control={mobileControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div className='banner-input-width'>
                                                    <Input type='tell' style={{ border: 'none', padding: '15px', background: '#FEF3F2', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', width: '100%' }}
                                                        {...field} invalid={!!mobileErrors.mobile} placeholder='Enter Your 10 - Digit Mobile Number' />
                                                    {mobileErrors.mobile && <FormFeedback>{mobileErrors.mobile.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                        <button className="banner-submit" type="submit">Submit</button>
                                    </div>
                                </Form>
                            </div>
                        )}

                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <div data-aos="zoom-in">
                            <img src="https://samsel.s3.ap-south-1.amazonaws.com/printonportfolio.png" className='img-fluid' style={{ height: '750px', position: 'relative', bottom: '40px', }} />
                        </div>
                    </Col>
                </Row>
                <div className='mt-5' style={{ background: 'white', borderRadius: '8px', position: 'relative', bottom: '180px' }}>
                    <Row>
                        <Col lg={3} md={6} sm={12}>
                            <div className='d-flex' style={{ padding: '20px', justifyContent: 'center' }}>
                                <img src={logo1} style={{ height: '85px' }} />
                                <div >
                                    <div className='d-flex'>
                                        <span style={{ fontWeight: '600', fontSize: '19px', color: '#484848', margin: '10px 0px 0px 5px' }}>+</span>
                                        <CountUp duration={5} className="banner-card-content" end={2000} />
                                    </div>
                                    {/* <p className='banner-card-content'>+2000</p> */}
                                    <div> <p className='ms-2' style={{ fontWeight: '600', fontSize: '19px', color: '#484848', display: 'flex' }}>Subscriber's</p></div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className='d-flex' style={{ padding: '20px', justifyContent: 'center' }}>
                                <img src={logo2} style={{ height: '85px' }} />
                                <div>
                                    <div className='d-flex'>
                                        <span style={{ fontWeight: '600', fontSize: '19px', color: '#484848', margin: '10px 0px 0px 5px' }}>+</span>
                                        <CountUp duration={5} className="banner-card-content" end={20} />
                                    </div>
                                    {/* <p className='banner-card-content'>+20</p> */}
                                    <p className='ms-2' style={{ fontWeight: '600', fontSize: '19px', color: '#484848', display: 'flex' }}>Years Of Trust</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className='d-flex' style={{ padding: '20px', justifyContent: 'center' }}>
                                <img src={logo3} style={{ height: '85px' }} />
                                <div>
                                    <div className='d-flex'>
                                        <span style={{ fontWeight: '600', fontSize: '19px', color: '#484848', margin: '10px 0px 0px 5px' }}>+</span>
                                        <CountUp duration={5} className="banner-card-content" end={2000} />
                                    </div>
                                    {/* <p className='banner-card-content'>+2000</p> */}
                                    <p className='ms-2' style={{ fontWeight: '600', fontSize: '19px', color: '#484848' }}>Branche's</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className='d-flex' style={{ padding: '20px', justifyContent: 'center' }}>
                                <img src={logo1} style={{ height: '90px' }} />
                                <div>
                                    <div className='d-flex'>
                                        <span style={{ fontWeight: '600', fontSize: '19px', color: '#484848', margin: '10px 0px 0px 5px' }}>+</span>
                                        <CountUp duration={5} className="banner-card-content" end={2000} />
                                    </div>
                                    {/* <p className='banner-card-content'>+2000</p> */}
                                    <p className='ms-2' style={{ fontWeight: '600', fontSize: '19px', color: '#484848' }}>Branche's</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <OtpSend isOpen={isOtpModalOpen} toggleOtpModal={() => setIsOtpModalOpen(!isOtpModalOpen)} />
            </div>

        </div>
    )
}

export default Herosection
