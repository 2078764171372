import React, { useEffect, useState } from 'react'
import './MyChit.css';
import { Card, Col, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import axios from 'axios';
import { myChits } from '../../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import Details from './details/Details';
import AuctionDetails from './auction/AuctionDetails';
import PayHistory from './payHistory/PayHistory';

function MyChit() {

    const [myChitData, setMyChitData] = useState([])
    const myChitDataDetails = async () => {
        try {
            const response = await axios.get(myChits);
            setMyChitData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    useEffect(() => {
        myChitDataDetails();
        window.scrollTo(0, 0);
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const detail = "Details"
    const auction = "Auction Details"
    const payHistory = "PayHistory"

    return (
        <div style={{ marginTop: '120px', paddingLeft: '8%', paddingRight: '8%', marginBottom: '30px' }}>
            <div>
                {myChitData.length === 0 ? (
                    <div style={{ height: '100%' }}>
                        <h3 className='text-center'>Your Chit is Empty</h3>
                    </div>
                ) : (

                    <Row>
                        {myChitData?.map((detail) => (
                            <Col lg={6} md={6} sm={12}>
                                <div>
                                    <Card>
                                        <div className='d-flex' style={{ paddingLeft: '10px', paddingRight: '10px', borderBottom: '1px solid #D2D2D2' }}>
                                            <div className='d-flex'>
                                                <img src={detail?.img_Url} style={{ height: '50px', width: '50px', marginTop: '15px', objectFit: 'cover', borderRadius: '100%' }} />
                                                <h5 className='myChit-name'>{detail?.chitName}</h5>
                                            </div>
                                            <div style={{ marginLeft: 'auto', marginTop: '15px' }}>
                                                <h6 >Chit Id</h6>
                                                <p >{detail?.chitId}</p>
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                            <Row>
                                                <Col lg={3} md={3} sm={3}>
                                                    <div className='myChit-firstRow'>
                                                        <h5 >Chit Value</h5>
                                                        <h5 className='single-chit-amnt'>{detail?.goalValue?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 0,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h5>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} sm={3}>
                                                    <div className='myChit-firstRow'>
                                                        <h5 >Current Due</h5>
                                                        <h5 className='single-chit-amnt'>{detail?.currentDue?.toLocaleString('en-IN', {
                                                            maximumFractionDigits: 0,
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}</h5>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} sm={3}>
                                                    <div className='myChit-firstRow'>
                                                        <h5 className='single-chit-head'>Chit Due Date</h5>
                                                        <h5 className='single-chit-body'>{formatDate(detail?.dueDate)}</h5>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={3} sm={3}>
                                                    <div className='myChit-firstRow'>
                                                        <h5 className='single-chit-head'>Duration</h5>
                                                        <h5 className='single-chit-body'>{detail?.totalDuration}-{detail?.durationType}</h5>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <Nav className='myChit-tabs' tabs fill>
                                            <NavItem>
                                                <NavLink className='tab-name'
                                                    active={active === '1'}
                                                    onClick={() => {
                                                        toggle('1')
                                                    }}
                                                >
                                                    Details
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className='tab-name'
                                                    active={active === '2'}
                                                    onClick={() => {
                                                        toggle('2')
                                                    }}
                                                >
                                                    AuctionDetails
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className='tab-name'
                                                    active={active === '3'}
                                                    onClick={() => {
                                                        toggle('3')
                                                    }}>
                                                    Pay History
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                        <TabContent className='py-50' activeTab={active}>
                                            <TabPane tabId='1'>
                                                <Details myChitData={detail} />
                                            </TabPane>
                                            <TabPane tabId='2'>
                                                <AuctionDetails type={auction} chitId={detail?.chitId} installmentId={detail?.istalmentId} />
                                            </TabPane>
                                            <TabPane tabId='3'>
                                                <PayHistory type={payHistory} />
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )}


            </div>
        </div>
    )
}

export default MyChit