
import React, { useEffect, useState } from 'react';
import './OurSolution.css';
import { Col, Row } from 'reactstrap';
import Aos from 'aos';
import Carousel from 'react-multi-carousel';
import { FiArrowUpRight } from "react-icons/fi";
import { goals } from '../../ApiConfigs/ApiConfigs';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import left from '../../assets/images/carousal/Frame 1410111145 (1).png';

function OurSolution() {
    const [slideData, setSlideData] = useState({ rows: [] });
    const navigate = useNavigate();

    const personalisedData = async () => {
        try {
            const response = await axios.get(`${goals}?type=personal`);
            setSlideData(response?.data?.result || { rows: [] });
        } catch (error) {
            toast.error(error?.response?.data?.msg || "Error fetching data");
        }
    };

    useEffect(() => {
        personalisedData();
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 767, min: 320 },
            items: 1,
            slidesToSlide: 1
        }
    };

    useEffect(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            <div className='mt-3'>
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <p className='our-solution-head'> OurSolution</p>
                        <div className='our-solution-line'></div>
                        <div data-aos='fade-up-right'>
                            <img src={left} className='our-solution-img img-fluid' alt='image' style={{ height: '100%' }} />
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={12}>
                        <p className='our-solution-para'>
                            Enjoy flexible saving and borrowing options tailored to your financial needs and goals. We operate with full transparency, ensuring that you understand every aspect of your chit fund journey.
                        </p>
                        <div>
                            <div className="parent">
                                <Carousel
                                    responsive={responsive}
                                    autoPlay={true}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={true}
                                    partialVisible={false}
                                    arrows={false}
                                    dotListClass="custom-dot-list-style"
                                >
                                    {slideData.rows.map((details, i) => (
                                        <div className="slider" key={i} onClick={() => navigate(`/growthInvestments/${details?._id}`)}>
                                            <div style={{ margin: '8px' }}>
                                                <img src={details.image} alt={details.title} />
                                            </div>
                                            <div className='our-solution-content'>
                                                <div className='our-solution-slide'>
                                                    <h5 className="card-title">{details?.title?.length > 25 ? `${details?.title?.slice(0, 25)}...` : details?.title}</h5>
                                                    <p className="card-text">{details?.description?.length > 105 ? `${details?.description?.slice(0, 105)}...` : details?.description}</p>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                                                <div className='our-solution-link'>
                                                    <FiArrowUpRight size={30} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default OurSolution;
