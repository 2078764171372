import React, { useEffect, useState } from 'react'
import './Solutions.css';
import { Col, Row, Spinner } from 'reactstrap';
import design from '../../assets/images/solutions/Frame 1597881730 (1).png';
import growth from '../../assets/images/solutions/Frame 1410111245.png';
import rightman from '../../assets/images/carousal/Frame 1410111145 (1).png';
import Aos from 'aos';
import axios from 'axios';
import { goals } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";



function Solutions() {

    const [goalData, setGoalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const goalBaseData = async () => {
        try {
            const response = await axios.get(`${goals}?type=goalbased`);
            setGoalData(response?.data?.result?.rows);
            setLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        goalBaseData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])

    const navToHome = () => {
        navigate('/')
    }

    return (
        <div>
            <div>
                {loading ? (
                    <div style={{ height: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            size=""
                            style={{ color: '#D32728' }}
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <div style={{ marginTop: '100px', paddingLeft: '8%', paddingRight: '8%' }}>
                        <Row className='solution-row'>
                            <Col lg={8} md={8} sm={12}>
                                <div className='solution-must mb-5'>
                                    <div className='solution-top-one'>
                                        <div className='solution-top '>
                                            <div className='solution-design'>
                                                <img src={design} />
                                            </div>
                                            <div className='solution-contents' >
                                                <h4 className='solution-head-content'>Meet Your Goals With Ease</h4>
                                                <div className='solution-line'></div>
                                                <p className='solution-head-desc'>Based on your goals and savings capacity, we match you with the most suitable chit group. Whether you're looking for a high-return investment or steady savings, we have options for everyone.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='solution-left-clr' style={{ paddingRight: '22%' }}>
                                    <div className='d-flex'>
                                        <div data-aos="flip-left" >
                                            <img src={growth} height={160} className='mt-5' />
                                        </div>
                                        <div className='solution-left-man'>
                                            <img src={rightman} height={235} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div >
                            <div><p className='solution-bredcrump pt-3' style={{ cursor: 'pointer' }}><span onClick={navToHome}>Home</span> <span style={{ color: '#D32728' }}><IoIosArrowForward /> </span><span style={{ color: 'grey' }}>Goal Based Solutions</span> </p></div>

                            <div className='mb-5'>
                                <h2 className='solutions-heading'>Goal Based Solutions</h2>
                                <div className='solution-head-line' style={{ margin: '0 auto' }} ></div>
                                <p className='solution-desc mt-3'><span className='solution-desc-dark'>CHITBID</span> chits is specifically designed to enable you to save and meet your borrowing needs,all at once</p>
                            </div>
                            {goalData?.map((detail, i) => (
                                <Row key={i}>
                                    {i % 2 === 0 ? (
                                        <>
                                            <Col lg={5} md={5} sm={12}>
                                                <div data-aos="fade-right" className='fade-right'>
                                                    <img src={detail.image} className='first-one-image ' />
                                                    <span class="wdp-ribbon wdp-ribbon-three" onClick={() => navigate(`/growthInvestments/${detail?._id}`)}>View Plan</span>
                                                </div>
                                            </Col>
                                            <Col lg={7} md={7} sm={12}>
                                                <div style={{ paddingLeft: '4%', paddingRight: '4%', paddingTop: '5%' }}>
                                                    <div>
                                                        <h4 className='first-one-header'>{detail.title}</h4>
                                                        <p className='first-one-description'>{detail.description}</p>
                                                    </div>
                                                    <div>
                                                        <Row>
                                                            {detail.details.map((subDetail, j) => (
                                                                <Col lg={6} md={6} sm={6} key={j}>
                                                                    <div>
                                                                        <div className='d-flex mt-2' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={subDetail.image} className='logo1-image' />
                                                                            <div className='ms-3 mt-2'>
                                                                                <h6 className='first-one-subhead1'>{subDetail.title}</h6>
                                                                                <p className='first-one-subdesc1'>{subDetail.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                            <hr className='mt-5 mb-5' style={{ height: '4px' }} />
                                        </>
                                    ) : (
                                        <>
                                            <Col lg={7} md={7} sm={12}>
                                                <div style={{ paddingLeft: '4%', paddingRight: '4%', paddingTop: '5%' }}>
                                                    <div>
                                                        <h4 className='first-one-header'>
                                                            {detail.title.length > 50 ? `${detail.title.slice(0, 50)}...` : detail.title}
                                                        </h4>
                                                        <p className='first-one-description'>
                                                            {detail.description.length > 145 ? `${detail.description.slice(0, 145)}...` : detail.description}

                                                        </p>
                                                    </div>
                                                    <div>
                                                        <Row>
                                                            {detail?.details?.map((subDetail, j) => (
                                                                <Col lg={6} md={6} sm={6} key={j}>
                                                                    <div>
                                                                        <div className='d-flex mt-2' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                            <img src={subDetail.image} className='logo1-image' />
                                                                            <div className='ms-3 mt-2'>
                                                                                <h6 className='first-one-subhead1'>{subDetail.title}</h6>
                                                                                <p className='first-one-subdesc1'>{subDetail.description}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={5} md={5} sm={12}>
                                                <div data-aos="fade-left" className='fade-right'>
                                                    <img src={detail.image} className='first-one-image ' />
                                                    <span class="wdp-ribbon2 wdp-ribbon-three" onClick={() => navigate(`/growthInvestments/${detail?._id}`)}>View Plan</span>
                                                </div>
                                            </Col>
                                            <hr className='mt-5 mb-5' />
                                        </>
                                    )}
                                </Row>
                            ))}
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default Solutions