import React, { useEffect, useState } from 'react'
import './AuctionDetails.css';
import axios from 'axios';
import { auctionWinner } from '../../../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { Card, Col, Row } from 'reactstrap';
import cup from '../../../../assets/images/logo/CUp2.png';

function AuctionDetails({ chitId, installmentId }) {

    const [installmentData, setInstallmentData] = useState([]);

    const installmentDetails = async () => {
        try {
            const response = await axios.get(`${auctionWinner}?chit_id=${chitId}`);
            setInstallmentData(response?.data?.result);
        } catch (error) {
            toast.error(error?.responce?.data?.msg);
        }
    }

    useEffect(() => {
        installmentDetails();
    }, []);


    // const auctionDetails = [
    //     {
    //         id: 1,
    //         number: '5',
    //         head: 'Auction Result',
    //         name: 'SasiUllah',
    //         date: '12/03/24',
    //         winning: '₹80,000',
    //         divident: '₹1,000',
    //         winRound: '18',
    //     },
    //     {
    //         id: 2,
    //         number: '4',
    //         head: 'Auction Result',
    //         name: 'Sasibalan',
    //         date: '12/02/24',
    //         winning: '₹85,000',
    //         divident: '₹800',
    //         winRound: '9',
    //     },
    //     {
    //         id: 3,
    //         number: '3',
    //         head: 'Auction Result',
    //         name: 'Delhi Babu',
    //         date: '12/01/24',
    //         winning: '₹88,000',
    //         divident: '₹500',
    //         winRound: '5',
    //     },
    //     {
    //         id: 4,
    //         number: '2',
    //         head: 'Auction Result',
    //         name: 'Karthik',
    //         date: '12/12/23',
    //         winning: '₹90,000',
    //         divident: '₹300',
    //         winRound: '21',
    //     },
    //     {
    //         id: 5,
    //         number: '1',
    //         head: 'Auction Result',
    //         name: 'Ragunathan',
    //         date: '12/11/23',
    //         winning: '₹95,000',
    //         divident: '₹100',
    //         winRound: '3',
    //     },
    // ]


    return (
        <div>
            <div style={{ marginTop: '30px' }}>
                <Row>
                    {installmentData.map((cardDetail) => (
                        <Col lg={6} md={6} sm={6}>
                            <div className='mb-3 p-2'>
                                <Card>
                                    <div>
                                        <div style={{ display: 'flex', padding: '10px', background: '#F5DADA' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', color: '#ED292D' }}>
                                                <div><img src={cup} style={{ height: '30px' }} /></div>
                                                <span className='ms-1'>#</span>
                                                <h5 className=' mt-1 ms-1'>{cardDetail?.number}</h5>
                                            </div>
                                            <div style={{ margin: '0 auto' }}>
                                                <h5>{cardDetail?.head}</h5>
                                            </div>
                                        </div>
                                        <div style={{ padding: '10px' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div><h6>Winner Name</h6>
                                                    <div style={{ color: '#ED292D' }}><h6>{cardDetail?.customer_id?.name}</h6></div>
                                                </div>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <h6>Wining Amount</h6>
                                                    <h6 style={{ color: '#ED292D', textAlign: 'end' }}>{cardDetail?.winningAmount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ padding: '10px' }}>
                                            <div className='d-flex'>
                                                <div><h6>Dividend Amount</h6></div>
                                                <div style={{ marginLeft: 'auto', textAlign: 'end', color: '#ED292D' }}><h6>{cardDetail?.single_bidAmount}</h6></div>
                                            </div>
                                            <div className='d-flex'>
                                                <div><h6>Winner Round</h6></div>
                                                <div style={{ marginLeft: 'auto', textAlign: 'end', color: '#ED292D' }}><h6>{cardDetail?.winningRound}</h6></div>
                                            </div>
                                            <div className='d-flex'>
                                                <div><h6>Chit Date</h6></div>
                                                <div style={{ marginLeft: 'auto', color: '#ED292D' }}><h6>{cardDetail?.date}</h6></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

export default AuctionDetails