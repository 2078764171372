import React, { useState } from 'react'
import './WhyChoosUs.css';
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap';
import whyLeft from '../../assets/images/whyChoosus/diverse-group-office-employee-worker-shake-hand-after-making-agreement-strategic-business-marketing-meeting-teamwork-positive-attitude-create-productive-supportive-workplace-prudent 2 (2).png';
import check from '../../assets/images/whyChoosus/Group 1410110236.png';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import { otpSend } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import OtpSend from '../../components/navbar/OtpSend';


function WhyChooseUs() {
    const logInMobileSchema = yup.object().shape({
        mobile: yup.string().required('Please Fill Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
    });

    const {
        handleSubmit: mobileFormSubmit,
        control: mobileControl,
        formState: { errors: mobileErrors },
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(logInMobileSchema) })

    const { isLoggedIn, logout } = useAuth();
    const [isOtpModalOpen, setIsOtpModalOpen] = useState(false); // State to control the visibility of the OTP modal

    const handleMobileLogIn = async (data) => {

        try {
            const response = await axios.post(otpSend, data);
            toast.success(response?.data?.msg);
            localStorage.setItem('SignInMobile', data.mobile);
            setIsOtpModalOpen(true); // Open the OTP modal after submitting the mobile number form
            reset()
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    return (
        <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div className='mt-5'>
                        <img src={whyLeft} className=' why-choos-img img-fluid' />
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <div className='mt-5'>
                        <h4 className='why-choos-head'>Why Choose Us</h4>
                        <div className='chit-head-line'></div>
                        <h4 className='why-choos-desc mt-3'>YOUR TRUSTABLE PLATFORM AND YOUR IS GROWTH MONEY</h4>
                        <h6 className='why-choos-para mt-4'>Customized solutions as per your needs.ChitBit is your guide to investing in saving cum borrowing product with ease Customized solutions as per your needs .</h6>
                    </div>
                    <div className='d-flex mt-5'>
                        <img src={check} height={30} />
                        <p className='why-choos-check ms-4'>Your Personal Problem Solution In Your Growth </p>
                    </div>
                    <div className='d-flex'>
                        <img src={check} height={30} />
                        <p className='why-choos-check ms-4'>Your Personal Problem Solution In Your Growth </p>
                    </div>
                    <div className='d-flex'>
                        <img src={check} height={30} />
                        <p className='why-choos-check ms-4'>Your Personal Problem Solution In Your Growth </p>
                    </div>
                    {isLoggedIn ? null : (
                        <div className='why-choose-input mt-5'>
                            <Form onSubmit={mobileFormSubmit(handleMobileLogIn)}>
                                <div>
                                    <Controller
                                        name='mobile'
                                        id='mobile'
                                        control={mobileControl}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className='why-choose-number'>
                                                <Input type='tell' style={{ border: 'none', padding: '15px', background: '#FEF3F2', width: '400px' }}
                                                    {...field} invalid={!!mobileErrors.mobile} placeholder='Enter Your 10 - Digit Mobile Number' />
                                                {mobileErrors.mobile && <FormFeedback>{mobileErrors.mobile.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                    <button className="why-choos-submit" type="submit">Submit</button>
                                </div>
                            </Form>
                        </div>
                    )}
                </Col>
            </Row>
            <OtpSend isOpen={isOtpModalOpen} toggleOtpModal={() => setIsOtpModalOpen(!isOtpModalOpen)} />

        </div>
    )
}

export default WhyChooseUs