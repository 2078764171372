import React, { useState } from 'react';
import { XCircle } from 'react-feather-icon';
import './OtpSend.css';
import { MdOutlineEdit } from "react-icons/md";
import { Button, Form, Label, Modal, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import OTPInput from 'react-otp-input';
import { otpSend, otpVerify } from '../../ApiConfigs/ApiConfigs';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useAuth } from '../../AuthContext';

function OtpSend({ isOpen, toggleOtpModal }) {

    const toggle = () => {
        toggleOtpModal();
    };
    const mobile = localStorage.getItem('SignInMobile')
    const [otp, setOtp] = useState('');
    const [otpLoading, setOtpLoading] = useState(false);

    const { login } = useAuth()

    const { reset } = useForm()
    const handleOTPLogIn = async () => {

        const payload = {
            mobile: mobile,
            otp: otp
        }


        try {
            setOtpLoading(true)
            const response = await axios.post(otpVerify, payload);
            login()
            console.log(response);
            toast.success(response?.data?.msg);
            localStorage.setItem('token', response?.data?.result?.tokens?.accessToken);
            localStorage.setItem('refreshToken', response?.data?.result?.tokens?.refreshToken);
            localStorage.setItem('role', response?.data?.result?.user?.role?.name);
            localStorage.setItem('userId', response?.data?.result?.user?._id);
            localStorage.setItem('name', response?.data?.result?.user?.name);
            localStorage.setItem('mobile', response?.data?.result?.user?.mobile)
            localStorage.removeItem('SignInMobile');
            toggleOtpModal()
            reset();
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
            setOtpLoading(false)
        }
    };

    const ResendOTP = async () => {
        const payload = {
            mobile: mobile
        }

        try {
            const response = await axios.post(otpSend, payload);
            toast.success(response?.data?.msg);
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    return (
        <div>
            <Modal isOpen={isOpen}>
                <ModalBody>
                    <ModalBody>
                        <div>
                            <div style={{ padding: "0% 3%" }}>
                                <div className='d-flex'>
                                    <p className='otp-send'> Verify Your Number</p>
                                    <div className='otp-modal-close' onClick={() => {
                                        toggle()
                                        setOtp('')
                                    }}> <XCircle size={28} /></div>
                                </div>
                                <div className='d-flex'>
                                    <p className='otp-send-number'>+91-{mobile}</p>
                                    <span className='otp-modal-edit ms-2' onClick={() => {
                                        // Open MobileOtp modal
                                        toggleOtpModal();
                                    }}><MdOutlineEdit size={26} /></span>
                                </div>
                                <div className='mt-4'>
                                    <Label className='otp-modal-head'>Enyter Your 6 Digit OTP</Label>
                                    <div className='d-flex'>
                                        <OTPInput
                                            className='otp-input-box'
                                            value={otp}
                                            onChange={(e) => setOtp(e)}
                                            inputType='tell'
                                            numInputs={6}
                                            inputStyle='custom-otp-field'
                                            renderInput={(props) => <input {...props} />}

                                        />
                                    </div>
                                    <div className='otp-resend-content mt-2'><p >Haven't Received yet? <span className='otp-resend text-primary' onClick={ResendOTP}>Resend OTP !</span> </p></div>
                                    <div className="mt-4 xl:mt-6 flex justify-between items-center">
                                        <Button
                                            className="w-full px-4 py-3 align-top xl:w-32 xl:mr-3"
                                            onClick={handleOTPLogIn}
                                            type="submit"
                                            isLoading={otpLoading}
                                            disabled={!otp || otp.length !== 6}
                                            style={{ width: '100%', cursor: 'pointer', background: '#ED292D', border: 'none' }}
                                        >
                                            Verify & Continue
                                        </Button>
                                    </div >
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default OtpSend;
