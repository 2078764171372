import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from '../App';
import NavbarTop from '../components/navbar/NavbarTop';
import { AuthProvider } from '../AuthContext';
import Footer from '../components/footer/Footer';
import Solutions from '../components/solutions/Solutions';
import PageNotFound from '../containers/notFound/PageNotFound';
import logo from '../assets/images/logo/CHit Logo.png';
import AllChits from '../containers/all Chits/AllChits';
import PersonalDetails from '../components/personalDetails/PersonalDetails';
import Growth from '../containers/goalBasedSolution/growth/Growth';
import SingleChit from '../containers/singleChit/SingleChit';
import MyChit from '../containers/userMenus/myChit/MyChit';
import CustomerDetails from '../containers/customerDetails/CustomerDetails';

function AppRouter({ currentPath }) {
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState()
    useEffect(() => {
        const handleBeforeUnload = () => {
            setLoading(true);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

        setPath(window.location.pathname)
    }, []);

    return (
        <div>
            <AuthProvider>
                <BrowserRouter>
                    {loading ? (
                        <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={logo} width={200} />
                        </div>
                    ) : (
                        <>
                            {path !== '/customer-details' && (
                                <>
                                    <NavbarTop />

                                </>
                            )}
                            {/* <NavbarTop /> */}
                            <Routes>
                                <Route path="*" element={<PageNotFound />} />
                                <Route path='/' element={<App />} />
                                <Route path='/solutions' element={<Solutions />} />
                                <Route path='/allchits' element={<AllChits />} />
                                <Route path='/personalDetails' element={<PersonalDetails />} />
                                <Route path='/growthInvestments/:id' element={<Growth />} />
                                <Route path='/chitView/:id' element={<SingleChit />} />
                                <Route path='/myChit' element={<MyChit />} />
                                <Route path='/customer-details' element={<CustomerDetails />} />
                            </Routes>
                            {/* <Footer /> */}
                            {path !== '/customer-details' && (
                                <>

                                    <Footer />
                                </>
                            )}
                        </>
                    )}
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
}

export default AppRouter;
