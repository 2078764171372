import React, { useEffect, useState } from 'react'
import './ChitCycle.css';
import star from '../../assets/images/cyclechit/Vector (5).png';
import curve from '../../assets/images/cyclechit/Vector 494.png';
import first from '../../assets/images/cyclechit/Frame 1410111003.png';
import second from '../../assets/images/cyclechit/Frame 1410110953.png';
import third from '../../assets/images/cyclechit/Frame 1410110952.png';
import fourth from '../../assets/images/cyclechit/Frame 1410110954.png';

function ChitCycle() {

    const [windowwidth, setwindowwidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleresize = () => {
            setwindowwidth(window.innerWidth)
        }
        window.addEventListener("resize", handleresize)
        return () => {
            window.addEventListener("resize", handleresize)
        }
    }, [])

    return (
        <div style={{ paddingLeft: '8%', paddingRight: '8%', background: ' #F7F7F8' }}>
            <div className='mt-5 pt-5'>

                <h2 className='chit-cycle-head'>The Chit Cycle Or The Chit Process</h2>
                <div className='d-flex' style={{ justifyContent: 'center' }}>
                    <div className='chit-cycle-line'></div>
                    <div className='d-flex'>
                        <div><img src={star} className='me-2' /></div>
                        <p className='cycle-chit-assume mt-1'>LET'S ASSUME</p>
                        <div><img src={star} className='ms-2' /></div>
                    </div>
                    <div className='chit-cycle-line'></div>
                </div>
                <div className='cycle-chit-desctext'>
                    <p className='pt-2' >Chit Value (Chit Amount =Rs.5 Lakhs,Join Members:50 Months,Auction Finalised Your Saving & Growth At 305,)</p>
                </div>

                <div className='chit-cycle-curve'>
                    <div className='chit-cycle-bck'> <img src={curve} className='cycle-chit-curve' /></div>
                    <div className={` ${windowwidth >= 992 ? "d-flex" : "flex-wrap"}`}>
                        <div className=' chit-cycle-upper d-flex'>
                            <div className='chit-cycle-first'>
                                <img src={first} className='cycle-chit-book' />
                                <h5 className='cycle-chit-book-plan'>PLAN</h5>
                                <p className='cycle-chit-book-desc'>chitbid is your guide to investing in saving product with ease </p>
                            </div>
                            <div className='chit-cycle-second'>
                                <img src={second} className='cycle-chit-hand' />
                                <h5 className='cycle-chit-book-bid'>BID AUCTION</h5>
                                <p className='cycle-chit-book-biddesc'>chitbid is your guide to investing in saving product with ease </p>
                            </div>
                        </div>
                        <div className='chit-cycle-upper d-flex'>
                            <div className='chit-cycle-third'>
                                <img src={third} className='cycle-chit-amnt' />
                                <h5 className='cycle-chit-book-inves'>INVEST</h5>
                                <p className='cycle-chit-book-invesdesc'>chitbid is your guide to investing in saving product with ease </p>
                            </div>
                            <div className='chit-cycle-fourth'>
                                <img src={fourth} className='cycle-chit-grow' />
                                <h5 className='cycle-chit-book-save'>GROWTH & SAVING</h5>
                                <p className='cycle-chit-book-savedesc'>chitbid is your guide to investing in saving product with ease </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ChitCycle