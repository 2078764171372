import React from 'react'
import './Details.css';
import { Col, Row } from 'reactstrap'
import calendar from '../../../../assets/images/myChit/Calendar.png'

function Details({ type, myChitData }) {
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    return (
        <div>
            <div style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '30px' }}>
                <Row>
                    <Col lg={4} md={4} sm={4}>
                        <div className='myChit-Details'>
                            <h5 className='single-chit-amnt'>{myChitData?.totalPaid?.toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })}</h5>
                            <h6>Total Paid</h6>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='myChit-Details'>
                            <h5 className='single-chit-amnt'>{myChitData?.totalEarned?.toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                            })}</h5>
                            <h6>Total Earned</h6>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='myChit-Details'>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <h6>{myChitData?.completedAuction}</h6>/<h6>{myChitData?.totalDuration}</h6>
                            </div>
                            <h6>Complete Chit</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <div className='myChit-AmntHead' >
                            <h4>Amount Payable Details</h4>
                        </div>
                    </Col>
                </Row>
                <Row className='solution-row'>
                    <Col lg={5} md={5} sm={5}>
                        <div className='myChit-detail-leftSide'>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={calendar} className='myChit-detail-calendar' />
                                </div>
                                <div className='myChit-firstRow'>
                                    <h5 className='single-chit-head'>Chit Due Date</h5>
                                    <h5 className='single-chit-body'>{formatDate(myChitData?.dueDate)}</h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} md={7} sm={7}>
                        <div className='mb-5' style={{ border: '1px solid #D2D2D2' }}>
                            <div className='prev-due'>
                                <div>
                                    <h6>Previous Due</h6>
                                </div>
                                <div className='amount-payable-left'><h6>{myChitData?.previousDue?.toLocaleString('en-IN', {
                                    maximumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'INR'
                                })}</h6></div>
                            </div>
                            <div className='prev-due'>
                                <div>
                                    <h6>Current Due</h6>
                                </div>
                                <div className='amount-payable-left'><h6>{myChitData?.currentDue?.toLocaleString('en-IN', {
                                    maximumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'INR'
                                })}</h6></div>
                            </div>
                            <div className='prev-due'>
                                <div>
                                    <h6>Late Payment</h6>
                                </div>
                                <div className='amount-payable-left'><h6>{myChitData?.latePayment?.toLocaleString('en-IN', {
                                    maximumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'INR'
                                })}</h6></div>
                            </div>
                            <div className='prev-due'>
                                <div>
                                    <h6>Net Payable</h6>
                                </div>
                                <div className='amount-payable-left'><h6>{myChitData?.netPayable?.toLocaleString('en-IN', {
                                    maximumFractionDigits: 0,
                                    style: 'currency',
                                    currency: 'INR'
                                })}</h6></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Details