import React, { useEffect, useState } from 'react'
import './TrendingChit.css';
import { Card, Col, Row } from 'reactstrap';
import axios from 'axios';
import { chits, goals } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function TrendingChit() {

    const navigate = useNavigate()
    // chit get
    const [data, setData] = useState([]);
    const chitCardData = async () => {
        try {
            const response = await axios.get(chits);
            setData(response?.data?.result?.rows);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        chitCardData();
        goalBaseData();
    }, []);

    // goal based get
    const [goalData, setGoalData] = useState([]);

    const goalBaseData = async () => {
        try {
            const response = await axios.get(`${goals}?type=goalbased`);
            setGoalData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    return (
        <div className='chit-section'>
            <div style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div className='mt-3'>
                    <div style={{ justifyContent: 'center' }}>
                        <div className='chit-head' style={{ textAlign: 'center' }}>Trending Chit's</div>
                        <div className='chit-head-line' style={{ margin: '0 auto' }}></div>
                    </div>
                    <div className='mt-5'>
                        <Row>
                            {data?.slice(0, 6)?.map((eachDetail, i) => (
                                <Col sm={6} md={6} lg={4} key={i}>
                                    <Card className='mb-4' style={{ boxShadow: ' 0px 18px 22px rgba(0, 0, 0, 0.1)' }}>
                                        <div className="d-flex mt-2" style={{ color: '#ED292D', padding: '15px' }}>
                                            <div className='d-flex'>
                                                <img src={eachDetail?.img_Url} style={{ height: '50px', width: '50px', objectFit: 'cover', borderRadius: '100%' }} />
                                                <h5 className='trending-card-head ms-2 mt-1'>{eachDetail?.chitName.length > 25 ? `${eachDetail?.chitName.slice(0, 25)}` : eachDetail?.chitName}</h5>
                                            </div>
                                            <div style={{ marginLeft: 'auto', textAlign: 'end' }}>
                                                <h6 className='trending-card-left'>Chit Id</h6>
                                                <h6 className='trending-card-left'>{eachDetail?.chit_id.length > 10 ? `${eachDetail?.chit_id.slice(0, 10)}` : eachDetail?.chit_id}</h6>
                                            </div>

                                        </div>
                                        {/* <h6 className='trending-card-left'>{eachDetail?.description}</h6> */}

                                        <hr style={{ marginTop: '0px' }} />
                                        <div className='d-flex p-3'>
                                            <div style={{ textAlign: 'start' }}>
                                                <h5 className='trending-card-left-head'>Chit Value:</h5>
                                                <h6 className='trending-card-left'>Available Ticket</h6>
                                                <h6 className='trending-card-left'>Duration</h6>
                                                <h6 className='trending-card-left'>Installment Amount</h6>
                                                <h6 className='trending-card-left'>Duration Type</h6>
                                                <h6 className='trending-card-left'>Chit Type</h6>
                                            </div>
                                            <div style={{ textAlign: 'end', marginLeft: 'auto' }}>
                                                <h5 className='trending-card-rate '>{eachDetail?.oneInstalmentTotalAmount?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 0,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</h5>
                                                <h6 className='trending-card-right'>{eachDetail?.membersCount}</h6>
                                                <h6 className='trending-card-right'>{eachDetail?.totalInstalment} {eachDetail?.durationType}</h6>
                                                <h6 className='trending-card-right'>{eachDetail?.oneInstalment?.toLocaleString('en-IN', {
                                                    maximumFractionDigits: 0,
                                                    style: 'currency',
                                                    currency: 'INR'
                                                })}</h6>
                                                <h6 className='trending-card-right'>{eachDetail?.durationType}</h6>
                                                <h6 className='trending-card-right'>{eachDetail?.status}</h6>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='p-3'>
                                            <button type='submit' className='chit-card-button' onClick={() => navigate(`/chitView/${eachDetail?._id}`)}>View Plan</button>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Link to='/allchits'>
                                <button type='button' className='chit-card-viewMore'>View More</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='chit-head mt-5' style={{ textAlign: 'center' }}>
                        Goal Based Solution
                    </div>
                    <div className='chit-head-line' style={{ margin: '0 auto' }}></div>
                    <p className='based-plan-head mt-3' style={{ textAlign: 'center' }}>Join a supportive community of members who share your financial aspirations,<br /> fostering collaboration and mutual benefit</p>
                    <div className='mt-5'>
                        <Row>
                            {goalData?.rows?.map((GoalDetail, i) => (
                                <Col sm={6} md={4} lg={3} key={i}>
                                    <Card className=' goal-base-card mb-4'>
                                        <div>
                                            <img src={GoalDetail.image} className='goal-base-img' />
                                        </div>
                                        <div className='p-3'>
                                            <h5 className='goal-base-head'>
                                                {GoalDetail.title.length > 40 ? `${GoalDetail.title.slice(0, 40)}...` : GoalDetail.title}
                                            </h5>
                                            <p className='goal-base-desc'>
                                                {GoalDetail.description.length > 120 ? `${GoalDetail.description.slice(0, 120)}...` : GoalDetail.description}
                                            </p>
                                        </div>
                                        <div className='p-3'>
                                            <button type='submit' className='chit-card-button' onClick={() => navigate(`/growthInvestments/${GoalDetail?._id}`)} >View Plan</button>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TrendingChit