import React, { useState } from 'react'
import './CustomerDetails.css';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { userDetail } from '../../ApiConfigs/ApiConfigs';
import toast from 'react-hot-toast';


function CustomerDetails() {
    const customerDetailsSchema = yup.object().shape({
        name: yup.string().required('Please Fill Your Name'),
        chit: yup.string().required('Please Fill Your Chit No'),
        mobile: yup.string().required('Please Fill Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid Mobile Number'),
        dob: yup.string().required('Please Fill Your DOB'),
        email: yup.string().required('Please Fill Your Email Id'),
        altrmobile: yup.string().optional(),
        address: yup.string().required('Please Fill Your Address'),
        residence: yup.string().required('Please Fill Your residence'),
        pincode: yup.string().required('Please Fill Your Pin Code'),
        employename: yup.string().required('Please Fill Your Name'),
        designation: yup.string().required('Please Fill Your Designation'),
        service: yup.string().required('Please Fill Your Years Of Service'),
        employeAddress: yup.string().required('Please Fill Your Business Address'),
        income: yup.string().required('Please Fill Your Income'),
        otherIncome: yup.string().required('Please Fill Your Other Income'),
        totalIncome: yup.string().required('Please Fill Your Total Income'),
        emi: yup.string().required('Please Fill Your Commitements'),
        surplus: yup.string().required('Please Fill Your Surplus Income'),


    });

    const {
        handleSubmit: customerDetailFormSubmit,
        control: customerDetailControl,
        formState: { errors: customerDetailError },
        reset,
    } = useForm({ mode: 'onChange', resolver: yupResolver(customerDetailsSchema) })

    const [userDetailData, setUserDetailData] = useState([]);

    const userDetailSubmit = async () => {
        try {
            const response = await axios.post(userDetail);
            setUserDetailData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };


    return (
        <div className='newCustomerForm'>
            <div className='container'>
                <div className='newCustomerForm-head'>New Customer Enrollment Form</div>
                <div>
                    <Form onSubmit={customerDetailFormSubmit(userDetailSubmit)}>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Name Of Applicant</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='name'
                                            id='name'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.name} placeholder='Enter Your Name' />
                                                    {customerDetailError.name && <FormFeedback>{customerDetailError.name.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Chit No.</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='chit'
                                            id='chit'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.chit} placeholder='Enter Your Chit No' />
                                                    {customerDetailError.chit && <FormFeedback>{customerDetailError.chit.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Mobile Number</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='mobile'
                                            id='mobile'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='tell'
                                                        {...field} invalid={!!customerDetailError.mobile} placeholder='Enter Your Mobile Number' />
                                                    {customerDetailError.mobile && <FormFeedback>{customerDetailError.mobile.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Date Of Birth</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='dob'
                                            id='dob'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='date'
                                                        {...field} invalid={!!customerDetailError.dob} placeholder='DD/MM/YYYY' />
                                                    {customerDetailError.dob && <FormFeedback>{customerDetailError.dob.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Email Address</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='email'
                                            id='email'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='email'
                                                        {...field} invalid={!!customerDetailError.email} placeholder='Enter Your Email Id' />
                                                    {customerDetailError.email && <FormFeedback>{customerDetailError.email.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Alternate Mobile Number</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='altrmobile'
                                            id='altrmobile'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='tell'
                                                        {...field} invalid={!!customerDetailError.altrmobile} placeholder='Enter Your Alternate Mobile Number' />
                                                    {customerDetailError.altrmobile && <FormFeedback>{customerDetailError.altrmobile.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Present Address</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='address'
                                            id='address'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.address} placeholder='Enter Your Address' />
                                                    {customerDetailError.address && <FormFeedback>{customerDetailError.address.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Residence</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='residence'
                                            id='residence'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.residence} placeholder='Enter Your Residence' />
                                                    {customerDetailError.residence && <FormFeedback>{customerDetailError.residence.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-3 mb-4'>
                                    <div>
                                        <Label>Pin Code</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='pincode'
                                            id='pincode'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='tell'
                                                        {...field} invalid={!!customerDetailError.pincode} placeholder='Enter Your Pin Code' />
                                                    {customerDetailError.pincode && <FormFeedback>{customerDetailError.pincode.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='newCustomerForm-head'>Income/Employement Details</div>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Employer/Business Name</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='employename'
                                            id='employename'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.employename} placeholder='Enter Your Name' />
                                                    {customerDetailError.employename && <FormFeedback>{customerDetailError.employename.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Designation</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='designation'
                                            id='designation'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.designation} placeholder='Enter Your Designation' />
                                                    {customerDetailError.designation && <FormFeedback>{customerDetailError.designation.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Years Of Service</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='service'
                                            id='service'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.service} placeholder='Enter Your Years Of Service' />
                                                    {customerDetailError.service && <FormFeedback>{customerDetailError.service.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Employer/Business Address</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='employeAddress'
                                            id='employeAddress'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.employeAddress} placeholder='Enter Your Address' />
                                                    {customerDetailError.employeAddress && <FormFeedback>{customerDetailError.employeAddress.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Monthly/Salary Income</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='income'
                                            id='income'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.income} placeholder='Enter Your Income' />
                                                    {customerDetailError.income && <FormFeedback>{customerDetailError.income.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Monthly Other Income</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='otherIncome'
                                            id='otherIncome'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.otherIncome} placeholder='Enter Your Other Income' />
                                                    {customerDetailError.otherIncome && <FormFeedback>{customerDetailError.otherIncome.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Total Income</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='totalIncome'
                                            id='totalIncome'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.totalIncome} placeholder='Enter Your Total Income' />
                                                    {customerDetailError.totalIncome && <FormFeedback>{customerDetailError.totalIncome.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>EMI Plus Other Commitements</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='emi'
                                            id='emi'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.emi} placeholder='Enter Your Monthly Commitements' />
                                                    {customerDetailError.emi && <FormFeedback>{customerDetailError.emi.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='mt-2 mb-4'>
                                    <div>
                                        <Label>Sureplus Income</Label>
                                    </div>
                                    <div>
                                        <Controller
                                            name='surplus'
                                            id='surplus'
                                            control={customerDetailControl}
                                            defaultValue=''
                                            render={({ field }) => (
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <Input type='text'
                                                        {...field} invalid={!!customerDetailError.surplus} placeholder='Enter Your Surplus Income' />
                                                    {customerDetailError.surplus && <FormFeedback>{customerDetailError.surplus.message}</FormFeedback>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button type='submit' className='customerDetails-Button'>Proceed</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails